.referral-submission-data-container {
  .referral-submission-step-header {
    margin-bottom: 2.0rem;
    padding-top: 0.15rem;
    background-color: #0087c7;
    border-radius: 50% !important;
    border: 2px solid #0087c7;
    color: #ffffff;
    height: 40px;
    width: 40px;
    font-size: 1.375rem;
    line-height: 1.5;
    font-weight: 600;
    display: inline-block;
    text-align: center;
  }
  .referral-submission-title-header {
    color: #0087c7;
    font-size: 1.425rem;
    margin-left: 0.5rem;
    font-weight: 600;
  }
}
