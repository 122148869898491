

// Bootstrap styles
//@import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
//@import "../../node_modules/font-awesome/css/font-awesome.min.css";

.p-widget,
.p-widget .p-widget,
div.collection-table.p-table.p-widget.p-table-hoverable-rows,
div.p-tabview.p-widget.p-widget-content.p-corner-all.p-tabview-top,
div.p-table.p-widget.p-table-resizable.p-table-resizable-fit.p-table-hoverable-rows,
div.p-table.p-widget.p-table-hoverable-rows,
.p-widget > button, .p-widget input, .p-widget select, .p-widget textarea,
.p-table .p-widget .p-table-hoverable-rows {
  font-family: "Noto Sans", Arial, "Helvetica Neue", Helvetica,  sans-serif !important;
}

.tabview-styles {
  margin: 0px;
  padding: 0px;
}

.p-tabview-panels {
  border: none !important;
}

.p-tabview.p-component .p-tabview-nav li, .p-tabview.p-component > .p-tabview-nav li a {
  // padding: 0;
  //width: 49% !important;
  margin: 0 !important;
  font-weight: normal !important;
  text-align: center !important;
}


.p-dropdown  .p-dropdown-panel{
  // width: 205px;
  // height: 38px;
  min-width: 100% !important;
  width: max-content;
  font-size: 0.875rem;
  padding: 0;
}
.p-dropdown .p-dropdown-label {
  height: 38px !important;
}
.p-dropdown .p-inputtext, .p-dropdown .p-dropdown-trigger,
.p-multiselect .p-multiselect-label-container, .p-multiselect .p-multiselect-trigger  {
  background: transparent !important;
  color: #333333 !important;
}
body .p-dropdown, body .p-multiselect {
  border: 1px solid #ced4da !important;
}
// p-dropdown {
//   .p-dropdown {
//     height: 38px !important;
//     width: 295px !important;
//     padding: 0;
//     margin:0;
//     text-align: left;
//     font-size: 0.875rem;
//   }
//   .p-dropdown-label {
//     /* padding-right: 4em; */
//     text-align: left;
//     margin-left: 5px;
//     font-size: 0.875rem;
//     color: darkgray !important;
//   }
//   // .p-dropdown-label {
//   //   height: 38px !important;
//   //   min-width: 100% !important;
//   //   width: 100% !important;;
//   //   width: 290px !important;
//   //   padding: 0;
//   //   margin:0;
//   //   }
//   // .p-dropdown-label-container {
//   //   height: 38px !important;
//   //   min-width: 100% !important;
//   //   width: 100% !important;;
//   //   width: 290px !important;
//   //   padding: 0;
//   //   margin:0;
//   // }
// }
