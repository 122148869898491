@charset "UTF-8";
@import "../css/variables";

/* Defining body defaults. */

* {
  margin: 0;
  padding: 0;
  //border-radius: 0 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  // position: relative;
  min-height: 100%;
  overflow-x: hidden; /* Prevent scroll on narrow devices */
}

body {
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  height: 100%;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  font-family: $standard-font-family;
  font-size: $default-font-size;
  font-weight: normal;
  color: $gray-base;
  text-decoration: none;
  overflow-x: hidden; /* Prevent scroll on narrow devices */
}

/* Global Font Support */
/* ESV */

@font-face {
  font-family: $standard-font-family;
  src: url(./../assets/fonts/NotoSans-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: $standard-font-family;
  src: url(./../assets/fonts/NotoSans-Bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/**********************************************************************************************************
GLOBAL Color
These classes are divs used for preseational purposes only, such as spacers or float clearing.
***********************************************************************************************************/

/**********************************************************************************************************
 Color Overrides START
***********************************************************************************************************/

a {
  color: $default-anchor;
}

a:hover,
a:active {
  color: $brand-primary;
}

.bg-custom {
  background-color: transparent !important;
}

.bg-secondary {
  background-color: $default-anchor !important;
}

/**********************************************************************************************************
 Color Overrides END
***********************************************************************************************************/

/**********************************************************************************************************
Custom Colors START
***********************************************************************************************************/

.brandblue {
  color: $brand-primary !important;
}

.brandgray {
  color: $brand-gray !important;
}

.brandberry {
  color: $brand-berry !important;
}

.brandmcal {
  color: $brand-medi-cal !important;
}

.brandocc {
  color: $brand-occ !important;
}

.brandpace {
  color: $brand-pace !important;
}

.text-lighter {
  color: $gray;
}

.text-primary {
  color: $brand-primary !important;
}

a.text-primary:hover,
a.text-primary:focus {
  color: $text-primary-color !important;
}

.text-secondary {
  color: $text-primary-color !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
  color: $brand-primary !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover,
a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: $default-anchor !important;
}

a.text-info:hover,
a.text-info:focus {
  color: $default-anchor !important;
}

.text-warning {
  color: $brand-warning !important;
}

a.text-warning:hover,
a.text-warning:focus {
  color: $text-warning-hover-color !important;
}

.text-danger {
  color: $brand-danger !important;
}

a.text-danger:hover,
a.text-danger:focus {
  color: $text-danger-hover-color !important;
}

.asterisk {
  color: $red-color;
}

.errorMessage {
  color: $red-color;
}

/**********************************************************************************************************
Custom Colors End
***********************************************************************************************************/

/**********************************************************************************************************
Text Size Overrides Start
***********************************************************************************************************/

/* Font Sizing */

  h1,
  .h1 {
    font-size: 1.802rem;
    color: $brand-primary;
  }

  h2,
  .h2 {
    font-size: 1.602rem;
    color: $brand-secondary;
  }

  h3,
  .h3 {
    font-size: 1.424rem;
    color: $brand-tertiary;
  }

  h4,
  .h4 {
    font-size: 1.266rem;
    color: $brand-quad;
  }

  h5,
  .h5 {
    font-size: 1.125rem;
    color: $brand-quad;
  }

  .xxl {
    font-size: 72px;
    color: $brand-primary;
    text-align: center;
  }

  .xxl-masthead {
    font-size: 52px;
    font-weight: lighter;
    text-align: center;
    padding-bottom: 0px;
  }

  .xl {
    font-size: 48px;
    color: $default-anchor;
    text-align: center;
    margin-bottom: 30px;
  }

  .xl-left {
    font-size: 48px;
    color: $default-anchor;
    margin-bottom: 30px;
  }

  .xl-link {
    font-size: 30px;
    color: $brand-primary;
    display: block;
  }

  .xl-copy {
    font-size: 14pt;
  }
@media (max-width: 992px) {
}


/**********************************************************************************************************
End Text Size Overrides END
***********************************************************************************************************/

.fade-in {
  opacity: 1;
  display: block !important;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 3s;
}

@keyframes p-progress-spinner-color {
  100%,
  0% {
    stroke: $brand-secondary;
  }
  40% {
    stroke: $white-color;
  }
  66% {
    stroke: $brand-secondary;
  }
  80%,
  90% {
    stroke: $white-color;
  }
}

$subheader-height: 50px;

label {
  font-size: 1rem;
  font-weight: 600;
  color: $default-text-color;
}

input, select, textarea {
  font-size: 1rem;
  color: #495057 !important;
}

form {
  border: 1px $white-color;
  &.container {
    padding: 30px 50px;
  }
}

// h2 {
//   margin-top: 0;
//   margin-bottom: 18px;
// }

// h5 {
//   font-size: 16px;
// }
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-12 {
  margin-bottom: 20px;
}

.left-align {
  text-align: left;
}

.right-align {
  text-align: right;
}

.back-button {
  cursor: pointer;
  margin-right: 50px;
  text-transform: capitalize;
}
.next-button {
  text-transform: capitalize;
}

app-providerPortal {
  background-color: $body-background-color;
}

app-footer {
  position:relative;
  footer {
    background-color: #F0F8FC;
  }
}

.footer {
  // position: -webkit-sticky; /* Safari */
  // position: sticky;
  position: fixed;
  // position: absolute;
  height: $footer-height;
  line-height: $footer-height;
  /* Set the fixed height of the footer here */
  width: 100%;
  bottom: 0;
  padding-top: 16px;
  padding-bottom: 16px;
  color: $default-text-color;
  background-color: $brand-primary;
  font-size: 10pt;
  font-weight: 400;
  a:visited {
    color: $default-text-color;
  }
}

.footer-user {
  // position: -webkit-sticky; /* Safari */
  // position: sticky;
  position: fixed;
  // position: absolute;
  height: $footer-height;
  line-height: $footer-height;
  /* Set the fixed height of the footer here */
  width: 100%;
  bottom: 0;
  padding-top: 16px;
  padding-bottom: 16px;
  color: $brand-primary;
  background-color: $default-text-color;
  font-size: 10pt;
  font-weight: 400;
  a:visited {
    color: $brand-primary;
  }
}

.subheader {
  font-size: 20pt;
  .p-toolbar-group-left {
    background-color: $close-tray-color;
    height: $subheader-height;
    cursor: pointer;
    &:hover {
      color: $white-color;
      background-color: $caloptima-blue;
    }
    &.show-tray {
      padding-left: 50px;
      padding-right: 5px;
    }
    &.hide-tray {
      padding-left: 5px;
    }
  }

  .module-sidebar-item {
    font-size: $default-font-size;
    margin-top: 2px;
    padding-top: 3px;
    padding-left: 20px;
  }
  .subheader-title {
    display: flex;
    align-items: center;
    font-size: 20px;
    color: #52bdf4;
    line-height: $subheader-height;
    .module-icon {
      padding-left: 20px;
      img {
        height: 24px;
      }
    }
    .module-name {
      padding-left: 20px;
    }
    .module-subname {
      margin-left: 5px;
    }

    .module-slash {
      margin-left: 5px;
    }

    .module-caret {
      margin-left: 5px;
    }
  }
  .p-widget-header {
    height: 50px;
    border: none;
    background: $close-tray-color;
    color: #9ba4ab;
    padding: 0;
    .expand-collapse {
      font-size: 20px;
      &:hover {
        color: white;
        background-color: $default-anchor;
      }
    }
  }
}

.nav-link.dropdown-toggle span {
  font-size: $default-font-size !important;
}
.nav-item-user {
  display: flex;
  align-items: center;
}
.auto-margins {
  margin-left: auto;
  margin-right: auto;
}

.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex-inline {
  display: flex;
  flex-direction: row;
}
.form-control.ng-invalid.ng-touched {
  border: 1px solid $red-color;
}

.form-control {
  font-size: 1rem;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 2.25;
}
.invalid-input {
  border: 1px solid $red-color;
}
.header-spacer {
  margin-top: 100px;
}
.alert-danger {
  background-color: rgba(179, 0, 0, 0.08);
  color: $alert-danger-color;
  font-size: 12pt;
}
.fa-chevron-right {
  padding-left: 10px;
}
.fa-chevron-left {
  padding-right: 10px;
}

.fa-sign-out.expanded {
  transform: rotate(180deg);
}

/************************
Widget Common styling
*************************/
.card-container {
  box-shadow: 2px 4px 8px 2px rgba(0,0,0,0.2) !important;
  transition: 0.5s;
  border-radius: 10px; /* 5px rounded corners */
  border-radius: 1rem !important;
  height: 100%;
  padding: 0 1rem 1rem 1rem;
  
  .card-container-icon {
    flex: 0 1 auto;
    padding: 1.25rem 1.25rem 3rem 1.25rem;
    text-align: left;
    font-weight: bold;
    font-size: 2rem;
    color: #0087C7;
    height: 3rem;
  }

  .card-container-header {
    flex: 0 1 auto;
    padding: 20px;
    text-align: left;
    font-weight: bold;
    height: 51px;
  }

  .card-container-content {
      padding: 0.25rem 1.25rem;
  }
  .card-container-footer {
    padding: 0.25rem 1rem 1rem 1.25rem
  }
}

/**********************************************************************************************************
 Button btn - START
***********************************************************************************************************/

.btn {
  &.btn-primary {
    background-image: unset;
    background-color: $caloptima-blue;
    color: white;
    font-size: $default-font-size;
    margin-right: 25px;
    &:hover {
      background-color: $button-hover-color;
    }
  }
  &.btn-secondary,
  &.btn-outline-secondary {
    background-image: unset;
    background-color: white;
    color: #868e96;
    border: 1px solid #868e96;
    font-size: $default-font-size;
    margin-right: 25px;
    &:hover {
      background-color: $button-hover-color;
      color: white;
    }
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }
}

.btn-primary-registration {
  background-image: unset;
  //border-color: $brand-primary;
  width: 114px;
  height: 54px;
  background: #007bff;//radial-gradient(#49708f 2%, $brand-primary);
  color: white;
  font-size: 14pt !important;
  margin-right: 25px;
  &:hover {
    background-color: $button-hover-color;
    border-color: #0062cc;
    opacity: 0.65;
    color: white;
  }
  &:disabled {
    background: #aeaeae;
    color: white;
    border-color: #aeaeae;
  }
}
.btn-secondary-registration {
  color: $caloptima-blue;
  width: 114px;
  height: 60px;
  font-size: 14pt;
  border: 1px solid $caloptima-blue;

  &:hover {
    background-color: $button-hover-color;
    color: white;
  }
}

.btn-primary {
  color: #ffffff;
  background: #007bff; /* Standard syntax (must be last) */
  border-color: #00365b;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  color: #ffffff;
  background: #0087c7;
  border-color: #0087c7;
}

.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  background-image: none;
}

.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
  background-color: #aeaeae;
  border-color: #aeaeae;
}

.btn-primary .badge {
  color: #00365b;
  background-color: #ffffff;
}

.btn-secondary {
  color: #ffffff;
  background: #0087c7; /* For browsers that do not support gradients */
  border-color: #0087c7;
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active,
.btn-secondary.active,
.open .dropdown-toggle.btn-secondary {
  color: #ffffff;
  background: #00365b;
  border-color: #00365b;
}

.btn-secondary:active,
.btn-secondary.active,
.open .dropdown-toggle.btn-secondary {
  background-image: none;
}

.btn-secondary.disabled,
.btn-secondary[disabled],
fieldset[disabled] .btn-secondary,
.btn-secondary.disabled:hover,
.btn-secondary[disabled]:hover,
fieldset[disabled] .btn-secondary:hover,
.btn-secondary.disabled:focus,
.btn-secondary[disabled]:focus,
fieldset[disabled] .btn-secondary:focus,
.btn-secondary.disabled:active,
.btn-secondary[disabled]:active,
fieldset[disabled] .btn-secondary:active,
.btn-secondary.disabled.active,
.btn-secondary[disabled].active,
fieldset[disabled] .btn-secondary.active {
  background-color: #aeaeae;
  border-color: #aeaeae;
}

.btn-secondary .badge {
  color: #0087c7;
  background-color: #ffffff;
}

.btn-outline-search {
  color: #0087c7;
  background-color: transparent;
  background-image: none;
  border-color: #0087c7;
  margin: 0 10px;
}

.btn-outline-search:hover {
  color: #ffffff;
  background-color: #0087c7;
  border-color: #0087c7;
}

.btn-outline-search:focus,
.btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-onlinetools {
  padding: 30px;
}

.btn-search {
  padding: 20px 0;
  font-size: 18pt;
}

.btn-dashboard {
  color: #ffffff;
  background: #0087c7; /* For browsers that do not support gradients */
  background: -webkit-radial-gradient(
    #5fa6d1 2%,
    #0087c7
  ); /* Safari 5.1 to 6.0 */
  background: -o-radial-gradient(
    #5fa6d1 2%,
    #0087c7
  ); /* For Opera 11.6 to 12.0 */
  background: -moz-radial-gradient(
    #5fa6d1 2%,
    #0087c7
  ); /* For Firefox 3.6 to 15 */
  background: radial-gradient(
    #5fa6d1 2%,
    #0087c7
  ); /* Standard syntax (must be last) */
  border-color: #0087c7;
  border-radius: 0;
  padding: 30px 10px;
}

.btn-dashboard:hover,
.btn-dashboard:focus,
.btn-dashboard:active,
.btn-dashboard.active,
.open .dropdown-toggle.btn-dashboard {
  color: #ffffff;
  background: #7fc3e3;
  background: -webkit-radial-gradient(#7fc3e3, #7fc3e3); /* Safari 5.1 to 6.0 */
  background: -o-radial-gradient(#7fc3e3, #7fc3e3); /* For Opera 11.6 to 12.0 */
  background: -moz-radial-gradient(
    #7fc3e3,
    #7fc3e3
  ); /* For Firefox 3.6 to 15 */
  background: radial-gradient(
    #7fc3e3,
    #7fc3e3
  ); /* Standard syntax (must be last) */
  border-color: #7fc3e3;
}

.btn-dashboard:active,
.btn-dashboard.active,
.open .dropdown-toggle.btn-dashboard {
  background: #0087c7;
  background-image: none;
}

.btn-lvlfour {
  color: #ffffff;
  font-size: 12pt;
  background: #0087c7; /* For browsers that do not support gradients */
  background: -webkit-radial-gradient(
    #5fa6d1 2%,
    #0087c7
  ); /* Safari 5.1 to 6.0 */
  background: -o-radial-gradient(
    #5fa6d1 2%,
    #0087c7
  ); /* For Opera 11.6 to 12.0 */
  background: -moz-radial-gradient(
    #5fa6d1 2%,
    #0087c7
  ); /* For Firefox 3.6 to 15 */
  background: radial-gradient(
    #5fa6d1 2%,
    #0087c7
  ); /* Standard syntax (must be last) */
  border-color: #0087c7;
  border-radius: 0;
  padding: 10px 10px;
}

.btn-lvlfour:hover,
.btn-lvlfour:focus,
.btn-lvlfour:active,
.btn-lvlfour.active,
.open .dropdown-toggle.btn-lvlfour {
  color: #ffffff;
  background: #7fc3e3;
  background: -webkit-radial-gradient(#7fc3e3, #7fc3e3); /* Safari 5.1 to 6.0 */
  background: -o-radial-gradient(#7fc3e3, #7fc3e3); /* For Opera 11.6 to 12.0 */
  background: -moz-radial-gradient(
    #7fc3e3,
    #7fc3e3
  ); /* For Firefox 3.6 to 15 */
  background: radial-gradient(
    #7fc3e3,
    #7fc3e3
  ); /* Standard syntax (must be last) */
  border-color: #7fc3e3;
}

.btn-lvlfour:active,
.btn-lvlfour.active,
.open .dropdown-toggle.btn-lvlfour {
  background: #0087c7;
  background-image: none;
}

.btn-lvlfourmcal {
  color: #ffffff;
  background: #00365b; /* For browsers that do not support gradients */
  background: -webkit-radial-gradient(
    #49708f 2%,
    #00365b
  ); /* Safari 5.1 to 6.0 */
  background: -o-radial-gradient(
    #49708f 2%,
    #00365b
  ); /* For Opera 11.6 to 12.0 */
  background: -moz-radial-gradient(
    #49708f 2%,
    #00365b
  ); /* For Firefox 3.6 to 15 */
  background: radial-gradient(
    #49708f 2%,
    #00365b
  ); /* Standard syntax (must be last) */
  border-color: #00365b;
  border-radius: 0;
  padding: 10px 10px;
}

.btn-lvlfourmcal:hover,
.btn-lvlfourmcal:focus,
.btn-lvlfourmcal:active,
.btn-lvlfourmcal.active,
.open .dropdown-toggle.btn-lvlfourmcal {
  color: #ffffff;
  background: #0087c7;
  background: -webkit-radial-gradient(#0087c7, #0087c7); /* Safari 5.1 to 6.0 */
  background: -o-radial-gradient(#0087c7, #0087c7); /* For Opera 11.6 to 12.0 */
  background: -moz-radial-gradient(
    #0087c7,
    #0087c7
  ); /* For Firefox 3.6 to 15 */
  background: radial-gradient(
    #0087c7,
    #0087c7
  ); /* Standard syntax (must be last) */
  border-color: #0087c7;
}

.btn-lvlfourmcal:active,
.btn-lvlfourmcal.active,
.open .dropdown-toggle.btn-lvlfourmcal {
  background: #0087c7;
  background-image: none;
}

/**********************************************************************************************************
 Button btn - END
***********************************************************************************************************/

/**********************************************************************************************************
 Alerts- START
***********************************************************************************************************/

.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 0;
}

.alert h4 {
  margin-top: 0;
  color: inherit;
}

.alert .alert-link {
  font-weight: bold;
}

.alert > p,
.alert > ul {
  margin-bottom: 0;
}

.alert > p + p {
  margin-top: 5px;
}

.alert-success {
  background-color: rgba(70, 148, 8, 0.08);
  border-color: #d6e9c6;
  color: #469408;
  font-size: 0.875rem;
}

.alert-success hr {
  border-top-color: #c9e2b3;
}

.alert-success .alert-link {
  color: #356635;
}

.alert-info {
  background-color: rgba(0, 135, 199, 0.08);
  border-color: #bce8f1;
  color: #0087c7;
  font-size: 0.875rem;
}

.alert-info hr {
  border-top-color: #a6e1ec;
}

.alert-info .alert-link {
  color: #2d6987;
}

.alert-warning {
  background-color: rgba(217, 131, 31, 0.08);
  border-color: #fcd8ae;
  color: #d9831f;
  font-size: 0.875rem;
}

.alert-warning hr {
  border-top-color: #f8e5be;
}

.alert-warning .alert-link {
  color: #a47e3c;
}

.alert-danger {
  background-color: rgba(179, 0, 0, 0.08);
  border-color: #eed3d7;
  color: #b30000;
  font-size: 0.875rem;
}

.alert-danger hr {
  border-top-color: #e6c1c7;
}

.alert-danger .alert-link {
  color: #953b39;
}

.alert-error {
  color: #dc3545;
  font-size: 0.875rem;
  margin-top: 0.25rem
}

.alert-error hr {
  border-top-color: #e6c1c7;
}

.alert-error .alert-link {
  color: #953b39;
}

.alert-message {
  padding: 5px 10px;
  margin-bottom: 20px;
  font-family: $header-font-family;
  line-height: 21pt;
  width: 100%;
  &.alert-danger {
    background-color: $alert-danger-bg-color;
    color: $alert-danger-color;
    border: 1px solid $state-danger-border;
  }
  &.alert-warning {
    background-color: $alert-warning-bg-color;
    color: $alert-warning-color;
    border: 1px solid $alert-warning-border;
  }
  &.alert-success {
    background-color: $alert-success-bg-color;
    color: $alert-success-color;
    border: 1px solid $alert-success-border-color;
  }
}

/**********************************************************************************************************
 Alerts- END
***********************************************************************************************************/

/**********************************************************************************************************
 Dropdown - START
***********************************************************************************************************/

.dropdown-menu {
  line-height: 1.5rem;
  margin: 0 125px 0 0;
}

.dropdown-item {
  white-space: normal;
}

.dropdown-item a {
  color: #333333;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #ffffff;
  text-decoration: none;
  background-color: transparent !important;
}

/**********************************************************************************************************
 Dropdown - START
***********************************************************************************************************/

/*
 * Pagination
 * --------------------------------------------------
 */

.page-link {
  color: #0087c7;
}

.page-link:hover {
  color: #00365b;
}

.page-item.active .page-link {
  background-color: #0087c7;
  border-color: #0087c7;
}

.wizard {
  max-width: 60%;
  border: 1px solid $widget-border-color;
  padding: 20px;
}

.caloptima-close {
  color: $default-text-color;
  background-color: $close-background-color;
  &:hover {
    background-color: $close-hover-color;
    cursor: pointer;
  }
  &:active {
    background-color: $close-active-color;
  }
  .fa {
    font-size: 20px;
  }
}

/**********************************************************************************************************
 Text Behavior - START
***********************************************************************************************************/



.dont-break-out {

  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

}


/**********************************************************************************************************
 Media Queries - START
***********************************************************************************************************/



@media (max-width: 414px) {
  form {
    &.container {
      padding: 30px 20px;
    }
  }
}

